import { CRYPTO_SECRET_KEY, STATUS_LIST } from "config/config";
import CryptoJS from "crypto-js";
import { SERVER_FILE_PATH } from "config/config";
import {
  BRAND_LIST,
  SCAN_ALLOW,
  SPECIAL_ONLY,
  ACHIEVEMENT,
  ACHIEVERS_SHOW,
} from "utils/DataHelper";
import {
  OTHER_PRODUCT_SCAN,
  PRODUCT_CLAIM_REMINDER_INTERVAL,
  PRODUCT_TRANSFER_REMINDER_INTERVAL,
  ROLE,
  SCANNING_REMINDER_INTERVAL,
  SCANNING_REPORT_INTERVAL,
  STATUS,
} from "./DataHelper";

export const routeScrollToTop = () => {
  // called on route change
  window.$("html, body").animate({ scrollTop: 0 }, "300");
};
export const generateRandomNumber = () => parseInt(Date.now() * Math.random());
export const decryptObject = (obj) => {
  return JSON.parse(
    CryptoJS.AES.decrypt(obj, CRYPTO_SECRET_KEY).toString(CryptoJS.enc.Utf8)
  );
};
export const encryptObject = (obj) => {
  obj = JSON.stringify(obj);
  return CryptoJS.AES.encrypt(obj, CRYPTO_SECRET_KEY).toString();
};
export const capitalizeWord = (text) => {
  const words = text.split(" ");

  for (let i = 0; i < words.length; i++) {
    words[i] = words[i][0].toUpperCase() + words[i].substr(1);
  }
  return words.join(" ");
};
export const checkActiveRoute = (
  activeRoute,
  routeList,
  haveChild = false,
  matchExactRoute = false
) => {
  var className = "";
  if (!matchExactRoute) {
    const paths = activeRoute.split("/");
    className =
      paths.some((path) => routeList.includes(path)) ||
      activeRoute.includes(routeList[0])
        ? "active"
        : "";
  } else {
    className = routeList.every((e) => e.includes(activeRoute)) ? "active" : "";
  }
  if (haveChild && className == "active") {
    className += " show";
  }
  return className;
};
export const numberFormatShort = (numberVal, precision = 0) => {
  let n_format = "";
  let suffix = "T";
  if (numberVal < 999) {
    // 0 - 999
    n_format = numberVal.toFixed(precision);
    suffix = "";
  } else if (numberVal < 900000) {
    // 0.9k-850k
    n_format = (numberVal / 1000).toFixed(precision);
    suffix = "K";
  } else if (numberVal < 900000000) {
    // 0.9m-850m
    n_format = (numberVal / 1000000).toFixed(precision);
    suffix = "M";
  } else if (numberVal < 900000000000) {
    // 0.9b-850b
    n_format = (numberVal / 1000000000).toFixed(precision);
    suffix = "B";
  } else {
    // 0.9t+
    n_format = (numberVal / 1000000000000).toFixed(precision);
    suffix = "T";
  }
  return n_format + suffix;
};
export const allowedOnlyNumber = (event) => {
  const keyValue = event.key;
  const regex = /[0-9]/; // Only allow numbers

  if (
    !regex.test(keyValue) &&
    keyValue !== "Backspace" &&
    keyValue !== "Delete" && // Allow Delete key
    !(event.ctrlKey && keyValue === "a") && // Allow Ctrl + A for select all
    !(event.metaKey && keyValue === "a") && // Allow Cmd + A for select all (Mac)
    !(event.ctrlKey && keyValue === "c") && // Allow Ctrl + C for Copy
    !(event.ctrlKey && keyValue === "v") && // Allow Ctrl + V for Paste
    keyValue !== "Tab"
  ) {
    event.preventDefault();
  }
};
export const encryptText = (message) => {
  message = message.toString();
  const cipherText = CryptoJS.AES.encrypt(message, CRYPTO_SECRET_KEY);
  return encodeURIComponent(cipherText.toString());
};
export const placeParams = (pathRegex, params) => {
  const segments = pathRegex.split("/");
  return (
    segments
      .map((segment) => {
        let offset = segment.indexOf(":?") + 1;

        if (offset) {
          let key = segment.slice(offset + 1);
          return params[key];
        }

        offset = segment.indexOf(":") + 1;
        if (!offset) return segment;

        let key = segment.slice(offset);
        return params[key];
      })
      .join("/")
      // Remove trailing "/"
      .replace(/\/+$/, "")
      // Remove redundant "/"
      .replace(/\/{2,}/, "/")
  );
};
export const decryptText = (message) => {
  var bytes = CryptoJS.AES.decrypt(
    decodeURIComponent(message),
    CRYPTO_SECRET_KEY
  );
  return bytes.toString(CryptoJS.enc.Utf8);
};
export const getImageUrl = (type, path) => {
  let dir = SERVER_FILE_PATH;
  switch (type) {
    case "ticket":
      dir += "/image_library/ticket/";
      break;
    case "profile":
      dir += "/image_library/profile_images/";
      break;
    case "catalog":
      dir += "/image_library/catalog_images/";
      break;
    case "banner":
      dir += "/image_library/whatsapp_banner/";
      break;
    case "seminar":
      dir += "/image_library/seminar/";
      break;
    case "manual_notification":
      dir += "/image_library/manual_notification/";
      break;
    case "doc":
      dir += "/image_library/document/";
      break;
    case "whats_new":
      dir += "/image_library/banner_images/";
      break;
    case "app_banner":
      dir += "/image_library/banner_images/";
      break;
    case "video_section":
      dir += "/Admin/images/mvideo/";
      break;
    case "image":
      dir += "/Admin/images/mvideo/";
      break;
  }

  return dir + path;
};

export const formatSerialNo = (num, perfixNo = 4, prefixSign = "") => {
  let serialNum = prefixSign;
  serialNum += String(num).padStart(perfixNo, "0");
  return serialNum;
};

export const getRole = (role) => {
  switch (role.toLowerCase()) {
    case "retailer":
      return "sub-dealers";
    case "technician":
      return "Technician";
    case "dealer":
      return "Dealers";
    default:
      return "Unknown role";
  }
};
export const renderStr = (parameter) => {
  if (
    parameter == null ||
    parameter == "" ||
    parameter == "0000-00-00 00:00:00"
  ) {
    return "-";
  }
  return parameter;
};
export const getTruncatedString = (stringVal, length = 20) => {
  if (stringVal.length > length)
    stringVal = stringVal.substring(0, length) + "...";
  return stringVal;
};
export const calculateRewardPercentage = (
  totalScan,
  totalReward,
  perfix = 2
) => {
  // Ensure totalScan and totalReward are valid numbers
  if (
    typeof totalScan !== "number" ||
    typeof totalReward !== "number" ||
    totalScan < 0 ||
    totalReward < 0
  ) {
    throw new Error(
      "Invalid input. Both totalScan and totalReward must be non-negative numbers."
    );
  }
  if (totalScan === 0) {
    return 0;
  } else {
    //return (totalReward / totalScan) * 100;
    return Number(((totalReward / totalScan) * 100).toFixed(perfix));
  }
};
export const getBrandTypeObjBySlug = (slug) => {
  var selectedSlug = null;
  const filteredData = BRAND_LIST.filter(function (item) {
    return item.slug == slug;
  });
  if (filteredData.length > 0) {
    selectedSlug = filteredData[0];
  }
  return selectedSlug;
};
export const getRoleTypeObjBySlug = (slug) => {
  var selectedSlug = null;
  const filteredData = ROLE.filter(function (item) {
    return item.slug == slug;
  });
  if (filteredData.length > 0) {
    selectedSlug = filteredData[0];
  }
  return selectedSlug;
};
export const getScanIntervalObjBySlug = (slug) => {
  var selectedSlug = null;
  const filteredData = SCANNING_REPORT_INTERVAL.filter(function (item) {
    return item.slug == slug;
  });
  if (filteredData.length > 0) {
    selectedSlug = filteredData[0];
  }
  return selectedSlug;
};
export const getScanReminderIntervalObjBySlug = (slug) => {
  var selectedSlug = null;
  const filteredData = SCANNING_REMINDER_INTERVAL.filter(function (item) {
    return item.slug == slug;
  });
  if (filteredData.length > 0) {
    selectedSlug = filteredData[0];
  }
  return selectedSlug;
};
export const getProductTransferObjBySlug = (slug) => {
  var selectedSlug = null;
  const filteredData = PRODUCT_TRANSFER_REMINDER_INTERVAL.filter(function (
    item
  ) {
    return item.slug == slug;
  });
  if (filteredData.length > 0) {
    selectedSlug = filteredData[0];
  }
  return selectedSlug;
};
export const getProductClaimObjBySlug = (slug) => {
  var selectedSlug = null;
  const filteredData = PRODUCT_CLAIM_REMINDER_INTERVAL.filter(function (item) {
    return item.slug == slug;
  });
  if (filteredData.length > 0) {
    selectedSlug = filteredData[0];
  }
  return selectedSlug;
};
export const getOtherProductObjBySlug = (slug) => {
  var selectedSlug = null;
  const filteredData = OTHER_PRODUCT_SCAN.filter(function (item) {
    return item.slug == slug;
  });
  if (filteredData.length > 0) {
    selectedSlug = filteredData[0];
  }
  return selectedSlug;
};
export const getScanAlloweObjBySlug = (slug) => {
  var selectedSlug = null;
  const filteredData = SPECIAL_ONLY.filter(function (item) {
    return item.slug == slug;
  });
  if (filteredData.length > 0) {
    selectedSlug = filteredData[0];
  }
  return selectedSlug;
};
export const getSpacialOnlyObjBySlug = (slug) => {
  var selectedSlug = null;
  const filteredData = SCAN_ALLOW.filter(function (item) {
    return item.slug == slug;
  });
  if (filteredData.length > 0) {
    selectedSlug = filteredData[0];
  }
  return selectedSlug;
};
export const getAchievementObjBySlug = (slug) => {
  var selectedSlug = null;
  const filteredData = ACHIEVEMENT.filter(function (item) {
    return item.slug == slug;
  });
  if (filteredData.length > 0) {
    selectedSlug = filteredData[0];
  }
  return selectedSlug;
};
export const getAchieversShowObjBySlug = (slug) => {
  var selectedSlug = null;
  const filteredData = ACHIEVERS_SHOW.filter(function (item) {
    return item.slug == slug;
  });
  if (filteredData.length > 0) {
    selectedSlug = filteredData[0];
  }
  return selectedSlug;
};
export const getStatusObjBySlug = (slug) => {
  var selectedSlug = null;
  const filteredData = STATUS.filter(function (item) {
    return item.slug == slug;
  });
  if (filteredData.length > 0) {
    selectedSlug = filteredData[0];
  }
  return selectedSlug;
};
