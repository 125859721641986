const endpoints = {
  // login and profile module
  login: { url: "/login", method: "POST" },
  forgotPassword: { url: "/forgot-password", method: "POST" },
  updateUser: { url: "/profile", method: "POST" },
  getProfileDetails: { url: "/profile", method: "GET" },
  refreshToken: { url: "/refresh-token", method: "GET" },
  logout: { url: "/logout", method: "GET" },
  //FirstBanner
  updateAppBanner: { url: "/update-app-banner", method: "POST" },
  appBanner: { url: "/app-banner", method: "POST" },

  //second banner
  secondBannerList: { url: "/banner", method: "POST" },
  createSecondBanner: { url: "/create-banner", method: "POST" },
  secondBannerInfo: { url: "/banner-info", method: "POST" },
  manageSecondBanner: { url: "/manage-banner-order", method: "POST" },
  deleteSecondBanner: { url: "/delete-banner", method: "POST" },

  //third banner 
  thirdBannerList: { url: "/banner-announcement", method: "POST" },
  createThirdBanner: { url: "/create-banner-announcement", method: "POST" },
  thirdBannerInfo: { url: "/banner-announcement-info", method: "POST" },
  manageThirdBanner: { url: "/manage-banner-announcement-order", method: "POST" },
  deleteThirdBanner: { url: "/delete-banner-announcement", method: "POST" },

  //forth banner
    forthBannerList: { url: "/banner-secondary", method: "POST" },
    createForthBanner: { url: "/create-banner-secondary", method: "POST" },
    deleteForthBanner: { url: "/delete-banner-secondary", method: "POST" },
    updateForthBanner: { url: "/update-banner-secondary", method: "POST" },
    forthBannerInfo: { url: "/banner-secondary-info", method: "POST" },
    manageForthBanner: { url: "/manage-banner-secondary-order", method: "POST" },


  //dashboard
  dashboardData: { url: "/dashboard", method: "POST" },
  ticketData: { url: "/ticket", method: "POST" },
  CreateTicket: { url: "/create-ticket", method: "POST" },
  ticketInfo: { url: "/ticket-info", method: "POST" },
  replyTicket: { url: "/reply-on-ticket", method: "POST" },
  updateTicketStatus: { url: "/update-ticket-status", method: "POST" },
  assignTicket: { url: "/assign-ticket", method: "POST" },
  uploadManager: { url: "/upload-manager", method: "POSTFORMDATA" },
  // location
  locationMaster: { url: "/location-master", method: "POST" },

  //role
  roleList: { url: "/role", method: "POST" },
  roleInfo: { url: "/role-info", method: "POST" },
  addRoleData: { url: "/create-role", method: "POST" },
  manageRoleStatus: { url: "/manage-role-status", method: "POST" },
  updateRoleData: { url: "/update-role", method: "POST" },
  permissionList: { url: "/permission", method: "POST" },
  // staff
  staffList: { url: "/staff", method: "POST" },
  staffInfo: { url: "/staff-info", method: "POST" },
  addStaffData: { url: "/create-staff", method: "POST" },
  manageStaffStatus: { url: "/manage-staff-status", method: "POST" },
  updateStaffData: { url: "/update-staff", method: "POST" },
  deleteStaffData: { url: "/delete-staff", method: "POST" },
  //dealers
  dealersList: { url: "/dealer", method: "POST" },
  dealersInfo: { url: "/dealer-info", method: "POST" },
  updateDealersData: { url: "/update-dealer", method: "POST" },
  uploadImageManager: { url: "/upload-manager", method: "POST" },
  //sub-dealers
  subDealersList: { url: "/sub-dealer", method: "POST" },
  SubDealersReportInfo: { url: "/sub-dealer-info", method: "POST" },
  updateSubDealersReportData: { url: "/update-sub-dealer", method: "POST" },
  //technicians
  techniciansList: { url: "/technician", method: "POST" },
  techniciansReportInfo: { url: "/technician-info", method: "POST" },
  updateTechniciansReportData: { url: "/update-technician", method: "POST" },
  //catalog
  catalogReportList: { url: "/catalog", method: "POST" },
  catalogReportInfo: { url: "/catalog-info", method: "POST" },
  createCatalogReport: { url: "/create-catalog", method: "POST" },
  deleteCatalogReport: { url: "/delete-catalog", method: "POST" },
  updateCatalogReportData: { url: "/update-catalog", method: "POST" },
  manageCatalogOrder: { url: "/manage-catalog-order", method: "POST" },
  //category
  categoryReportList: { url: "/category", method: "POST" },
  categoryReportInfo: { url: "/category-info", method: "POST" },
  updateCategoryReportData: { url: "/update-category", method: "POST" },
  addCategoryReportData: { url: "/create-category", method: "POST" },
  manageOrderData: { url: "/manage-achievers-order", method: "POST" },
  
  //tutorialvideo
  tutorialVideoList: { url: "/tutorial-video", method: "POST" },
  tutorialVideoInfo: { url: "/tutorial-video-info", method: "POST" },
  updateTutorialVideo: { url: "/update-tutorial-video", method: "POST" },
  addTutorialVideo: { url: "/create-tutorial-video", method: "POST" },
  deleteTutorialVideo: { url: "/delete-tutorial-video", method: "POST" },
  manageTutorialVideo: { url: "/manage-tutorial-video-order", method: "POST" },


  //CatalogVideo
  catalogVideoList: { url: "/catalog-video", method: "POST" },
  catalogVideoInfo: { url: "/catalog-video-info", method: "POST" },
  updateCatalogVideo: { url: "/update-catalog-video", method: "POST" },
  addCatalogVideo: { url: "/create-catalog-video", method: "POST" },
  deleteCatalogVideo: { url: "/delete-catalog-video", method: "POST" },
  manageCatalogVideo: { url: "/manage-catalog-video-order", method: "POST" },


  //startup_banner
  startupBannerList: { url: "/startup-banner", method: "POST" },
  startupBannerInfo: { url: "/startup-banner-info", method: "POST" },
  updateStartupBanner: { url: "/update-startup-banner", method: "POST" },
  addStartupBanner: { url: "/create-startup-banner", method: "POST" },
  deleteStartupBanner: { url: "/delete-startup-banner", method: "POST" },
  manageStartupBanner: { url: "/manage-startup-banner-order", method: "POST" },


  // reward
  scanRewardList: { url: "/scan-reward", method: "POST" },
  createScanReward: { url: "/create-scan-reward", method: "POST" },
  manageScanRewardStatus: { url: "/manage-scan-reward-status", method: "POST" },
  scanRewardInfo: { url: "/scan-reward-info", method: "POST" },
  updateScanReward: { url: "/update-scan-reward", method: "POST" },
  //other
  lockUserList: { url: "/lock-user", method: "POST" },
  updateLockUserData: { url: "/lock-unlock-user", method: "POST" },
  //otp report
  otpList: { url: "/otp-report", method: "POST" },
  //dealer replaced product
  dealerReplacedProduct: { url: "/dealer-replaced-product", method: "POST" },
  //bonus point report
  bonusPointReportList: { url: "/get-bonus-point-report", method: "POST" },
  createBonusPointReport: { url: "/create-bonus-point", method: "POST" },
  deleteBonusPointReport: { url: "/delete-bonus-point", method: "POST" },
  //cart report
  cartReport: { url: "/cart-report", method: "POST" },
  //scan code report
  scanCodeList: { url: "/scan-code-report", method: "POST" },
  //dealer additional warranty report
  DealerAdditionalWarrantyReport: { url: "/dealer-additional-warranty-report", method: "POST" },
  //dealer additional warranty replacement report
  dealerAdditionalWarrantyReplacementReport: { url: "/dealer-additional-warranty-replacement-report", method: "POST" },
  //whatsapp message report
  whatsAppMessageReportList: {
    url: "/whatsapp-notification-report",
    method: "POST",
  },
  //maintenancesetting
  systemMaintenanceList: { url: "/system-maintenance", method: "GET" },
  updateSystemMaintenance: { url: "/system-maintenance", method: "POST" },
  //whatsapp notification
  whatsappNotificationList: {
    url: "/whatsapp-notification-setting",
    method: "GET",
  },
  updateWhatsappNotification: {
    url: "/whatsapp-notification-setting",
    method: "POST",
  },
  // check user detail repor
  checkUserDetailReport: { url: "/check-user-detail-report", method: "POST" },
  monthwiseScanPercentageReport: {
    url: "/month-wise-scan-percentage-report",
    method: "POST",
  },
  bonusPointAchieverReport: {
    url: "/bonus-points-achievers-report",
    method: "POST",
  },
  overallRewardAchievedReport: {
    url: "/overall-reward-achieved",
    method: "POST",
  },
  overallPointAchievedReport: {
    url: "/overall-point-achieved",
    method: "POST",
  },
  overallRewardRedeemReport: {
    url: "/overall-reward-redeem",
    method: "POST",
  },
  //auto notification setting
  autoNotificationList: { url: "/auto-notification-setting", method: "GET" },
  updateAutoNotification: { url: "/auto-notification-setting", method: "POST" },
  //outlate
  outlateList: { url: "/outlet", method: "POST" },
  updateOutlateStatus: { url: "/manage-outlet-status", method: "POST" },
  // point summary for dealer, sub dealer and technician
  pointSummaryList: { url: "/reward-point-summary", method: "POST" },
  // reward summary for dealer, sub dealer and technician
  rewardSummaryList: { url: "/reward-summary", method: "POST" },
  //verify-dealer-account
  verifyDealerAccountList: { url: "/verify-dealer-account", method: "POST" },
  //verify-sub-dealer-account
  verifySubDealerAccountList: {
    url: "/verify-sub-dealer-account",
    method: "POST",
  },
  //accept and reject
  approveRejectVerification: {
    url: "/approve-reject-verification",
    method: "POST",
  },
  //seminar
  seminarList: { url: "/seminar", method: "POST" },
  seminarInfo: { url: "/seminar-info", method: "POST" },
  seminarBookingList: { url: "/seminar-booking", method: "POST" },
  updateSeminarBookingRegistrationStatus: {
    url: "/update-seminar-registration",
    method: "POST",
  },
  addSeminarData: { url: "/create-seminar", method: "POST" },
  deleteSeminarData: { url: "/delete-seminar", method: "POST" },
  deleteSeminarBookingData: {
    url: "/delete-seminar-registration",
    method: "POST",
  },
  manageSeminarStatus: { url: "/manage-seminar-status", method: "POST" },
  updateSeminarData: { url: "/update-seminar", method: "POST" },
  //manual notification
  manualNotificationList: { url: "/manual-notification", method: "POST" },
  addManualNotificationData: {
    url: "/create-manual-notification",
    method: "POST",
  },
  deleteManualNotificationData: {
    url: "/delete-manual-notification",
    method: "POST",
  },
  updateManualNotificationData: {
    url: "/update-manual-notification",
    method: "POST",
  },
  manualNotificationInfo: { url: "/manual-notification-info", method: "POST" },
  //fult type
  fultTypeList: { url: "/fault-type", method: "POST" },
  addFultTypeData: { url: "/create-fault-type", method: "POST" },
  deleteFultTypeData: { url: "/delete-fault-type", method: "POST" },
  updateFultTypeData: { url: "/update-fault-type", method: "POST" },
  FultTypeInfo: { url: "/fault-type-info", method: "POST" },

    //marquee type
    marqueeTypeList: { url: "/marquee", method: "POST" },
    addMarqueeData: { url: "/create-marquee", method: "POST" },
    deleteMarqueeData: { url: "/delete-marquee", method: "POST" },
    updateMarqueeData: { url: "/update-marquee", method: "POST" },
    marqueeInfo: { url: "/marquee-info", method: "POST" },

    //New report type
    newReportList: { url: "/whats-new", method: "POST" },
    addNewReportData: { url: "/create-whats-new", method: "POST" },
    deleteNewReportData: { url: "/delete-whats-new", method: "POST" },
    updateNewReportData: { url: "/update-whats-new", method: "POST" },
    newReportInfo: { url: "/whats-new-info", method: "POST" },
    updateNewReportStatus: { url: "/update-whats-new-status", method: "POST" },
    manageWhatsNewOrder: { url: "/manage-whats-new-order", method: "POST" },

    //terms and condition
    termsAndCondition: { url: "/terms-and-condition", method: "POST" },
    
    termsAndConditionGet: { url: "/terms-and-condition", method: "GET" },




  //role change
  userChangeRole: { url: "/change-user-role", method: "POST" },
  //delete users
  deleteUsers: { url: "/delete-user", method: "POST" },
  //social media setting
  socialMediaSettingList: { url: "/contact-setting", method: "GET" },
  updateSocialMediaSetting: { url: "/contact-setting", method: "POST" },
};

export default endpoints;
