import React from "react";
import {
  mainRoutes as routes,
  loginRoutes,
  dealerRoutes,
  subDealerRoutes,
  techniciansRoutes,
  reportRoutes,
} from "config/routing";
import { Link, useLocation } from "react-router-dom";
import { checkActiveRoute } from "utils/CommonHelper";
import { useTranslation } from "react-i18next";
import { categoryMasterRoutes } from "config/routing";
import { catalogRoutes } from "config/routing";
import { otherModulRoutes } from "config/routing";
import { settingRoutes } from "config/routing";
import { notificationRoutes } from "config/routing";
import { staffRoutes } from "config/routing";
import { hasUserAccessFunctionality } from "utils/PermissionHelper";
import { permissionSlugs } from "utils/PermissionHelper";
import { rewardModulRoutes } from "config/routing";
import { bonusPointModulRoutes } from "config/routing";
import { bannnerModulRoutes } from "config/routing";
import { whatsNewReportModulRoutes } from "config/routing";

export default function NavbarLinks() {
  const location = useLocation();
  const { t } = useTranslation();
  let pathname = location.pathname;
  return (
    <div>
      <ul className="navbar-nav">
        <li className="nav-item">
          <Link
            to={routes.dashboard.path}
            className={`nav-link 
                        ${checkActiveRoute(
              pathname,
              [routes.dashboard.path],
              false,
              true
            )}
                      `}
          >
            <i className="ni ni-tv-2" style={{ color: "#16499b" }} />{" "}
            {t("dashboard")}
          </Link>
        </li>
        {hasUserAccessFunctionality([
          permissionSlugs.staff_list,
          permissionSlugs.staff_role_list,
        ]) && (
            <li
              className={`nav-item ${checkActiveRoute(
                pathname,
                [routes.staff.path],
                true
              )}`}
            >
              <Link
                href="#"
                className="nav-link"
                data-toggle="collapse"
                data-target="#staff-menu-content"
                aria-expanded={
                  checkActiveRoute(pathname, [routes.staff.path], true) != ""
                    ? true
                    : false
                }
              >
                <i className="fa fa-user-tie" style={{ color: "#16499b" }}></i>{" "}
                {t("staff")}
              </Link>
              <ul
                id="staff-menu-content"
              /* className="menu-content collapse out" */ className={`menu-content collapse ${checkActiveRoute(
                  pathname,
                  [routes.staff.path],
                  true
                )}`}
              >
                {hasUserAccessFunctionality([
                  permissionSlugs.staff_role_list,
                ]) && (
                    <li>
                      <Link
                        className={`dropdown-item 
                        ${checkActiveRoute(pathname, [
                          staffRoutes.roleList.path,
                        ])}
                      `}
                        to={routes.staff.path + "/" + staffRoutes.roleList.path}
                      >
                        <span>
                          <i className="ni ni-bold-right"></i>
                        </span>
                        {t("role")}
                      </Link>
                    </li>
                  )}
                {hasUserAccessFunctionality([permissionSlugs.staff_list]) && (
                  <li>
                    <Link
                      className={`dropdown-item 
                        ${checkActiveRoute(pathname, [
                        staffRoutes.staffList.path,
                      ])}
                      `}
                      to={routes.staff.path + "/" + staffRoutes.staffList.path}
                    >
                      <span>
                        <i className="ni ni-bold-right"></i>
                      </span>
                      {t("staff")}
                    </Link>
                  </li>
                )}
              </ul>
            </li>
          )}
        {hasUserAccessFunctionality([
          permissionSlugs.dealer_report,
          permissionSlugs.dealer_report_list,
        ]) && (
            <li
              className={`nav-item ${checkActiveRoute(
                pathname,
                [routes.dealer.path],
                true
              )}`}
            >
              <Link
                href="#"
                className="nav-link"
                data-toggle="collapse"
                data-target="#dealer-menu-content"
                aria-expanded={
                  checkActiveRoute(pathname, [routes.dealer.path], true) != ""
                    ? true
                    : false
                }
              >
                <i className="fa fa-user" style={{ color: "#16499b" }}></i>{" "}
                {t("dealers")}
              </Link>
              <ul
                id="dealer-menu-content"
              /* className="menu-content collapse out" */ className={`menu-content collapse ${checkActiveRoute(
                  pathname,
                  [routes.dealer.path],
                  true
                )}`}
              >
                {hasUserAccessFunctionality([
                  permissionSlugs.dealer_report_list,
                ]) && (
                    <li>
                      <Link
                        className={`dropdown-item 
                      ${checkActiveRoute(pathname, [
                          dealerRoutes.dealerReportList.path,
                        ])}
                    `}
                        to={
                          routes.dealer.path +
                          "/" +
                          dealerRoutes.dealerReportList.path
                        }
                      >
                        <span>
                          <i className="ni ni-bold-right"></i>
                        </span>
                        {t("dealer_report")}
                      </Link>
                    </li>
                  )}
                {hasUserAccessFunctionality([
                  permissionSlugs.verify_dealer_account_list,
                ]) && (
                    <li>
                      <Link
                        className={`dropdown-item 
                      ${checkActiveRoute(pathname, [
                          dealerRoutes.VerifyDealerAccountList.path,
                        ])}
                    `}
                        to={
                          routes.dealer.path +
                          "/" +
                          dealerRoutes.VerifyDealerAccountList.path
                        }
                      >
                        <span>
                          <i className="ni ni-bold-right"></i>
                        </span>
                        {t("verify_dealer_account")}
                      </Link>
                    </li>
                  )}
              </ul>
            </li>
          )}
        {hasUserAccessFunctionality([
          permissionSlugs.sub_dealer_report,
          permissionSlugs.sub_dealer_report_list,
        ]) && (
            <li
              className={`nav-item ${checkActiveRoute(
                pathname,
                [routes.subDealer.path],
                true
              )}`}
            >
              <Link
                href="#"
                className="nav-link"
                data-toggle="collapse"
                data-target="#subDealer-menu-content"
                aria-expanded={
                  checkActiveRoute(pathname, [routes.subDealer.path], true) != ""
                    ? true
                    : false
                }
              >
                <i
                  className="fas fa-user-friends"
                  style={{ color: "#16499b" }}
                ></i>{" "}
                {t("subDealers")}
              </Link>
              <ul
                id="subDealer-menu-content"
              /* className="menu-content collapse out" */ className={`menu-content collapse ${checkActiveRoute(
                  pathname,
                  [routes.subDealer.path],
                  true
                )}`}
              >
                {hasUserAccessFunctionality([
                  permissionSlugs.sub_dealer_report_list,
                ]) && (
                    <li>
                      <Link
                        className={`dropdown-item 
              ${checkActiveRoute(pathname, [
                          subDealerRoutes.subDealerReportList.path,
                        ])}
            `}
                        to={
                          routes.subDealer.path +
                          "/" +
                          subDealerRoutes.subDealerReportList.path
                        }
                      >
                        <span>
                          <i className="ni ni-bold-right"></i>
                        </span>
                        {t("subdealer_report")}
                      </Link>
                    </li>
                  )}
                {hasUserAccessFunctionality([
                  permissionSlugs.verify_sub_dealer_account_list,
                ]) && (
                    <li>
                      <Link
                        className={`dropdown-item 
                    ${checkActiveRoute(pathname, [
                          subDealerRoutes.VerifySubDealerAccountList.path,
                        ])}
                  `}
                        to={
                          routes.subDealer.path +
                          "/" +
                          subDealerRoutes.VerifySubDealerAccountList.path
                        }
                      >
                        <span>
                          <i className="ni ni-bold-right"></i>
                        </span>
                        {t("verify_sub_dealer_account")}
                      </Link>
                    </li>
                  )}
              </ul>
            </li>
          )}
        {hasUserAccessFunctionality([
          permissionSlugs.technician_report,
          permissionSlugs.technician_report_list,
        ]) && (
            <li
              className={`nav-item ${checkActiveRoute(
                pathname,
                [routes.technicians.path],
                true
              )}`}
            >
              <Link
                href="#"
                className="nav-link"
                data-toggle="collapse"
                data-target="#technicians-menu-content"
                aria-expanded={
                  checkActiveRoute(pathname, [routes.technicians.path], true) !=
                    ""
                    ? true
                    : false
                }
              >
                <i className="fas fa-users-cog" style={{ color: "#16499b" }}></i>{" "}
                {t("technicians")}
              </Link>
              <ul
                id="technicians-menu-content"
              /* className="menu-content collapse out" */ className={`menu-content collapse ${checkActiveRoute(
                  pathname,
                  [routes.technicians.path],
                  true
                )}`}
              >
                {hasUserAccessFunctionality([
                  permissionSlugs.technician_report_list,
                ]) && (
                    <li>
                      <Link
                        className={`dropdown-item 
                    ${checkActiveRoute(pathname, [
                          techniciansRoutes.techniciansReportList.path,
                        ])}
                  `}
                        to={
                          routes.technicians.path +
                          "/" +
                          techniciansRoutes.techniciansReportList.path
                        }
                      >
                        <span>
                          <i className="ni ni-bold-right"></i>
                        </span>
                        {t("technicians_report")}
                      </Link>
                    </li>
                  )}
              </ul>
            </li>
          )}
        {hasUserAccessFunctionality([
          permissionSlugs.otp_report,
          permissionSlugs.scan_code_report,
          permissionSlugs.whatsapp_message_report,
          permissionSlugs.check_user_detail_report_list,
          permissionSlugs.monthwise_scan_percentage_report,
          permissionSlugs.overall_reward_achieved_list,
          permissionSlugs.overall_point_achieved_list,
          permissionSlugs.overall_reward_redeem_list,
        ]) && (
            <li
              className={`nav-item ${checkActiveRoute(
                pathname,
                [routes.reports.path],
                true
              )}`}
            >
              <Link
                href="#"
                className="nav-link"
                data-toggle="collapse"
                data-target="#reports-menu-content"
                aria-expanded={
                  checkActiveRoute(pathname, [routes.reports.path], true) != ""
                    ? true
                    : false
                }
              >
                <i
                  className="fas fa-file-contract"
                  style={{ color: "#16499b" }}
                ></i>{" "}
                {t("reports")}
              </Link>
              <ul
                id="reports-menu-content"
              /* className="menu-content collapse out" */ className={`menu-content collapse ${checkActiveRoute(
                  pathname,
                  [routes.reports.path],
                  true
                )}`}
              >
                {hasUserAccessFunctionality([
                  permissionSlugs.otp_report_list,
                ]) && (
                    <li>
                      <Link
                        className={`dropdown-item 
                          ${checkActiveRoute(pathname, [
                          reportRoutes.otpReportList.path,
                        ])}
                        `}
                        to={
                          routes.reports.path +
                          "/" +
                          reportRoutes.otpReportList.path
                        }
                      >
                        <span>
                          <i className="ni ni-bold-right"></i>
                        </span>
                        {t("otp_report")}
                      </Link>
                    </li>
                  )}
                {hasUserAccessFunctionality([
                  permissionSlugs.scan_code_report,
                ]) && (
                    <li>
                      <Link
                        className={`dropdown-item 
                          ${checkActiveRoute(pathname, [
                          reportRoutes.scanCodeReportList.path,
                        ])}
                        `}
                        to={
                          routes.reports.path +
                          "/" +
                          reportRoutes.scanCodeReportList.path
                        }
                      >
                        <span>
                          <i className="ni ni-bold-right"></i>
                        </span>
                        {t("scan_code_report")}
                      </Link>
                    </li>
                  )}
                {hasUserAccessFunctionality([
                  permissionSlugs.whatsapp_message_report,
                ]) && (
                    <li>
                      <Link
                        className={`dropdown-item 
                          ${checkActiveRoute(pathname, [
                          reportRoutes.whatsappMessageReportList.path,
                        ])}
                        `}
                        to={
                          routes.reports.path +
                          "/" +
                          reportRoutes.whatsappMessageReportList.path
                        }
                      >
                        <span>
                          <i className="ni ni-bold-right"></i>
                        </span>
                        {t("whatsapp_massage_report")}
                      </Link>
                    </li>
                  )}
                {hasUserAccessFunctionality([
                  permissionSlugs.check_user_detail_report_list,
                ]) && (
                    <li>
                      <Link
                        className={`dropdown-item 
                          ${checkActiveRoute(pathname, [
                          reportRoutes.checkUserDetail.path,
                        ])}
                        `}
                        to={
                          routes.reports.path +
                          "/" +
                          reportRoutes.checkUserDetail.path
                        }
                      >
                        <span>
                          <i className="ni ni-bold-right"></i>
                        </span>
                        {t("check_user_details")}
                      </Link>
                    </li>
                  )}
                {hasUserAccessFunctionality([
                  permissionSlugs.overall_reward_achieved_list,
                ]) && (
                    <li>
                      <Link
                        className={`dropdown-item 
                          ${checkActiveRoute(pathname, [
                          reportRoutes.overallRewardAchievedList.path,
                        ])}
                        `}
                        to={
                          routes.reports.path +
                          "/" +
                          reportRoutes.overallRewardAchievedList.path
                        }
                      >
                        <span>
                          <i className="ni ni-bold-right"></i>
                        </span>
                        {t("overall_reward_achieved")}
                      </Link>
                    </li>
                  )}
                {hasUserAccessFunctionality([
                  permissionSlugs.overall_point_achieved_list,
                ]) && (
                    <li>
                      <Link
                        className={`dropdown-item 
                          ${checkActiveRoute(pathname, [
                          reportRoutes.overallPointAchievedList.path,
                        ])}
                        `}
                        to={
                          routes.reports.path +
                          "/" +
                          reportRoutes.overallPointAchievedList.path
                        }
                      >
                        <span>
                          <i className="ni ni-bold-right"></i>
                        </span>
                        {t("overall_point_achieved")}
                      </Link>
                    </li>
                  )}
                {hasUserAccessFunctionality([
                  permissionSlugs.overall_reward_redeem_list,
                ]) && (
                    <li>
                      <Link
                        className={`dropdown-item 
                          ${checkActiveRoute(pathname, [
                          reportRoutes.overallRewardRedeemList.path,
                        ])}
                        `}
                        to={
                          routes.reports.path +
                          "/" +
                          reportRoutes.overallRewardRedeemList.path
                        }
                      >
                        <span>
                          <i className="ni ni-bold-right"></i>
                        </span>
                        {t("overall_reward_redeem")}
                      </Link>
                    </li>
                  )}
                {hasUserAccessFunctionality([
                  permissionSlugs.monthwise_scan_percentage_report,
                ]) && (
                    <li>
                      <Link
                        className={`dropdown-item 
                          ${checkActiveRoute(pathname, [
                          reportRoutes.monthwiseScanPercentageReport.path,
                        ])}
                        `}
                        to={
                          routes.reports.path +
                          "/" +
                          reportRoutes.monthwiseScanPercentageReport.path
                        }
                      >
                        <span>
                          <i className="ni ni-bold-right"></i>
                        </span>
                        {t("monthwise_scan_percentage_report")}
                      </Link>
                    </li>
                  )}

                {hasUserAccessFunctionality([
                  permissionSlugs.cart_report_list,
                ]) && (
                    <li>
                      <Link
                        className={`dropdown-item 
                          ${checkActiveRoute(pathname, [
                          reportRoutes.cartReportList.path,
                        ])}
                        `}
                        to={
                          routes.reports.path +
                          "/" +
                          reportRoutes.cartReportList.path
                        }
                      >
                        <span>
                          <i className="ni ni-bold-right"></i>
                        </span>
                        {t("cart_report")}
                      </Link>
                    </li>
                  )}
                {hasUserAccessFunctionality([
                  permissionSlugs.company_dealer_replaced_product,
                ]) && (
                    <li>
                      <Link
                        className={`dropdown-item 
                          ${checkActiveRoute(pathname, [
                          reportRoutes.DealerReplacedProductsList.path,
                        ])}
                        `}
                        to={
                          routes.reports.path +
                          "/" +
                          reportRoutes.DealerReplacedProductsList.path
                        }
                      >
                        <span>
                          <i className="ni ni-bold-right"></i>
                        </span>
                        {t("dealer_replaced_products")}
                      </Link>
                    </li>
                  )}

                {hasUserAccessFunctionality([
                  permissionSlugs.dealer_additional_warranty_list,
                ]) && (
                    <li>
                      <Link
                        className={`dropdown-item 
                          ${checkActiveRoute(pathname, [
                          reportRoutes.DealerAdditionalWarrantyList.path,
                        ])}
                        `}
                        to={
                          routes.reports.path +
                          "/" +
                          reportRoutes.DealerAdditionalWarrantyList.path
                        }
                      >
                        <span>
                          <i className="ni ni-bold-right"></i>
                        </span>
                        {t("dealer_additional_warranty")}
                      </Link>
                    </li>
                  )}

                {hasUserAccessFunctionality([
                  permissionSlugs.dealer_additional_warranty_replacement_list,
                ]) && (
                    <li>
                      <Link
                        className={`dropdown-item 
                          ${checkActiveRoute(pathname, [
                          reportRoutes.DealerAdditionalWarrantyReplacementList.path,
                        ])}
                        `}
                        to={
                          routes.reports.path +
                          "/" +
                          reportRoutes.DealerAdditionalWarrantyReplacementList.path
                        }
                      >
                        <span>
                          <i className="ni ni-bold-right"></i>
                        </span>
                        {t("dealer_additional_warranty_replacement")}
                      </Link>
                    </li>
                  )}


              </ul>
            </li>
          )}
        {/* reward calculation */}
        {hasUserAccessFunctionality([permissionSlugs.scan_reward_list]) && (
          <li
            className={`nav-item ${checkActiveRoute(
              pathname,
              [routes.rewardModule.path],
              true
            )}`}
          >
            <Link
              href="#"
              className="nav-link"
              data-toggle="collapse"
              data-target="#reward-menu-content"
              aria-expanded={
                checkActiveRoute(pathname, [routes.rewardModule.path], true) !=
                  ""
                  ? true
                  : false
              }
            >
              <i className="fas fa-gift" style={{ color: "#16499b" }}></i>{" "}
              {t("reward")}
            </Link>
            <ul
              id="reward-menu-content"
              /* className="menu-content collapse out" */ className={`menu-content collapse ${checkActiveRoute(
                pathname,
                [routes.rewardModule.path],
                true
              )}`}
            >
              {hasUserAccessFunctionality([
                permissionSlugs.scan_reward_list,
              ]) && (
                  <li>
                    <Link
                      className={`dropdown-item 
                        ${checkActiveRoute(pathname, [
                        rewardModulRoutes.scanReward.path,
                      ])}
                      `}
                      to={
                        routes.rewardModule.path +
                        "/" +
                        rewardModulRoutes.scanReward.path
                      }
                    >
                      <span>
                        <i className="ni ni-bold-right"></i>
                      </span>
                      {t("scan_reward")}
                    </Link>
                  </li>
                )}
            </ul>
          </li>
        )}
        {/* bonus point */}
        {hasUserAccessFunctionality([
          permissionSlugs.bonus_point_achiever_list,
        ]) && (
            <li
              className={`nav-item ${checkActiveRoute(
                pathname,
                [routes.bonusPointModule.path],
                true
              )}`}
            >
              <Link
                href="#"
                className="nav-link"
                data-toggle="collapse"
                data-target="#bonus-point-menu-content"
                aria-expanded={
                  checkActiveRoute(
                    pathname,
                    [routes.bonusPointModule.path],
                    true
                  ) != ""
                    ? true
                    : false
                }
              >
                <i className="fas fa-coins" style={{ color: "#16499b" }}></i>{" "}
                {t("bonus_points")}
              </Link>
              <ul
                id="bonus-point-menu-content"
              /* className="menu-content collapse out" */ className={`menu-content collapse ${checkActiveRoute(
                  pathname,
                  [routes.bonusPointModule.path],
                  true
                )}`}
              >
                {hasUserAccessFunctionality([
                  permissionSlugs.bonus_point_achiever_list,
                ]) && (
                    <li>
                      <Link
                        className={`dropdown-item 
                        ${checkActiveRoute(pathname, [
                          bonusPointModulRoutes.bonusPointAchieverList.path,
                        ])}
                      `}
                        to={
                          routes.bonusPointModule.path +
                          "/" +
                          bonusPointModulRoutes.bonusPointAchieverList.path
                        }
                      >
                        <span>
                          <i className="ni ni-bold-right"></i>
                        </span>
                        {t("achiever_list")}
                      </Link>
                    </li>
                  )}

                {hasUserAccessFunctionality([
                  permissionSlugs.bonus_point_achiever_list,
                ]) && (
                    <li>
                      <Link
                        className={`dropdown-item 
                        ${checkActiveRoute(pathname, [
                          bonusPointModulRoutes.bonusPointReportList.path,
                        ])}
                      `}
                        to={
                          routes.bonusPointModule.path +
                          "/" +
                          bonusPointModulRoutes.bonusPointReportList.path
                        }
                      >
                        <span>
                          <i className="ni ni-bold-right"></i>
                        </span>
                        {t("bonus_points_report")}
                      </Link>
                    </li>
                  )}


              </ul>
            </li>
          )}
        {hasUserAccessFunctionality([
          permissionSlugs.category_report_list,
          permissionSlugs.achievement_order,
        ]) && (
            <li
              className={`nav-item ${checkActiveRoute(
                pathname,
                [routes.CategoryMaster.path],
                true
              )}`}
            >
              <Link
                href="#"
                className="nav-link"
                data-toggle="collapse"
                data-target="#category-menu-content"
                aria-expanded={
                  checkActiveRoute(
                    pathname,
                    [routes.CategoryMaster.path],
                    true
                  ) != ""
                    ? true
                    : false
                }
              >
                <i className="fas fa-sitemap" style={{ color: "#16499b" }}></i>{" "}
                {t("category_master")}
              </Link>
              <ul
                id="category-menu-content"
              /* className="menu-content collapse out" */ className={`menu-content collapse ${checkActiveRoute(
                  pathname,
                  [routes.CategoryMaster.path],
                  true
                )}`}
              >
                {hasUserAccessFunctionality([
                  permissionSlugs.category_report_list,
                ]) && (
                    <li>
                      <Link
                        className={`dropdown-item 
                    ${checkActiveRoute(pathname, [
                          categoryMasterRoutes.categoryReportList.path,
                        ])}
                  `}
                        to={
                          routes.CategoryMaster.path +
                          "/" +
                          categoryMasterRoutes.categoryReportList.path
                        }
                      >
                        <span>
                          <i className="ni ni-bold-right"></i>
                        </span>
                        {t("category_report")}
                      </Link>
                    </li>
                  )}
                {hasUserAccessFunctionality([
                  permissionSlugs.achievement_order,
                ]) && (
                    <li>
                      <Link
                        className={`dropdown-item 
                          ${checkActiveRoute(pathname, [
                          categoryMasterRoutes.achievementOrderList.path,
                        ])}
                        `}
                        to={
                          routes.CategoryMaster.path +
                          "/" +
                          categoryMasterRoutes.achievementOrderList.path
                        }
                      >
                        <span>
                          <i className="ni ni-bold-right"></i>
                        </span>
                        {t("achievement_order")}
                      </Link>
                    </li>
                  )}
              </ul>
            </li>
          )}
        {hasUserAccessFunctionality([
          permissionSlugs.catalog_report_list,
          permissionSlugs.catalog_order,
        ]) && (
            <li
              className={`nav-item ${checkActiveRoute(
                pathname,
                [routes.Catalog.path],
                true
              )}`}
            >
              <Link
                href="#"
                className="nav-link"
                data-toggle="collapse"
                data-target="#catalog-menu-content"
                aria-expanded={
                  checkActiveRoute(pathname, [routes.Catalog.path], true) != ""
                    ? true
                    : false
                }
              >
                <i className="fas fa-book-open" style={{ color: "#16499b" }}></i>{" "}
                {t("catalog")}
              </Link>
              <ul
                id="catalog-menu-content"
              /* className="menu-content collapse out" */ className={`menu-content collapse ${checkActiveRoute(
                  pathname,
                  [routes.Catalog.path],
                  true
                )}`}
              >
                {hasUserAccessFunctionality([
                  permissionSlugs.catalog_report_list,
                ]) && (
                    <li>
                      <Link
                        className={`dropdown-item 
                    ${checkActiveRoute(pathname, [
                          catalogRoutes.catalogReportList.path,
                        ])}
                  `}
                        to={
                          routes.Catalog.path +
                          "/" +
                          catalogRoutes.catalogReportList.path
                        }
                      >
                        <span>
                          <i className="ni ni-bold-right"></i>
                        </span>
                        {t("catalog_report")}
                      </Link>
                    </li>
                  )}
                {hasUserAccessFunctionality([
                  permissionSlugs.catalog_video,
                ]) && (
                    <li>
                      <Link
                        className={`dropdown-item 
                    ${checkActiveRoute(pathname, [
                          catalogRoutes.CatalogVideo.path,
                        ])}
                  `}
                        to={
                          routes.Catalog.path +
                          "/" +
                          catalogRoutes.CatalogVideo.path
                        }
                      >
                        <span>
                          <i className="ni ni-bold-right"></i>
                        </span>
                        {t("catalog_video")}
                      </Link>
                    </li>
                  )}
                {/* {hasUserAccessFunctionality([permissionSlugs.catalog_order]) && (
                  <li>
                    <Link
                      className={`dropdown-item 
                            ${checkActiveRoute(pathname, [
                        catalogRoutes.catalogOrderList.path,
                      ])}
                          `}
                      to={
                        routes.Catalog.path +
                        "/" +
                        catalogRoutes.catalogOrderList.path
                      }
                    >
                      <span>
                        <i className="ni ni-bold-right"></i>
                      </span>
                      {t("catalog_order")}
                    </Link>
                  </li>
                )} */}
              </ul>
            </li>
          )}
        {hasUserAccessFunctionality([permissionSlugs.outlet_list]) && (
          <li className="nav-item">
            <Link
              to={routes.outlate.path}
              className={`nav-link 
                        ${checkActiveRoute(
                pathname,
                [routes.outlate.path],
                false,
                false

              )}
                      `}
            >
              <i className="fas fa-store-alt" style={{ color: "#16499b" }} />{" "}
              {t("outlate")}
            </Link>
          </li>
        )}
        {hasUserAccessFunctionality([permissionSlugs.seminar_list]) && (
          <li className="nav-item">
            <Link
              to={routes.seminar.path}
              className={`nav-link 
                        ${checkActiveRoute(
                pathname,
                [routes.seminar.path],
                false,
                false
              )}
                      `}
            >
              <i
                className="fas fa-chalkboard-teacher"
                style={{ color: "#16499b" }}
              />{" "}
              {t("seminar")}
            </Link>
          </li>
        )}
        {hasUserAccessFunctionality([
          permissionSlugs.app_banner,
          // permissionSlugs.app_banner_list,
        ]) && (
            <li
              className={`nav-item ${checkActiveRoute(
                pathname,
                [routes.bannerModule.path],
                true
              )}`}
            >
              <Link
                href="#"
                className="nav-link"
                data-toggle="collapse"
                data-target="#banner-menu-content"
                aria-expanded={
                  checkActiveRoute(pathname, [routes.otherModule.path], true) !=
                    ""
                    ? true
                    : false
                }
              >
                <i
                  className="fas fa-solid fa-image"
                  style={{ color: "#16499b" }}
                ></i>{" "}
                {t("banner")}
              </Link>
              <ul
                id="banner-menu-content"
              /* className="menu-content collapse out" */ className={`menu-content collapse ${checkActiveRoute(
                  pathname,
                  [routes.bannerModule.path],
                  true
                )}`}
              >
                {hasUserAccessFunctionality([permissionSlugs.startup_banner]) && (
                  <li>
                    <Link
                      className={`dropdown-item 
                        ${checkActiveRoute(pathname, [
                        bannnerModulRoutes.startupBanner.path,
                      ])}
                      `}
                      to={
                        routes.bannerModule.path +
                        "/" +
                        bannnerModulRoutes.startupBanner.path
                      }
                    >
                      <span>
                        <i className="ni ni-bold-right"></i>
                      </span>
                      {t("startup_banner")}
                    </Link>
                  </li>
                )}
                {hasUserAccessFunctionality([permissionSlugs.app_banner]) && (
                  <li>
                    <Link
                      className={`dropdown-item 
                        ${checkActiveRoute(pathname, [
                        bannnerModulRoutes.firstBanner.path,
                      ])}
                      `}
                      to={
                        routes.bannerModule.path +
                        "/" +
                        bannnerModulRoutes.firstBanner.path
                      }
                    >
                      <span>
                        <i className="ni ni-bold-right"></i>
                      </span>
                      {t("First banner")}
                    </Link>
                  </li>
                )}

                {hasUserAccessFunctionality([permissionSlugs.banner_report]) && (
                  <li>
                    <Link
                      className={`dropdown-item 
                        ${checkActiveRoute(pathname, [
                        bannnerModulRoutes.secondBanner.path,
                      ])}
                      `}
                      to={
                        routes.bannerModule.path +
                        "/" +
                        bannnerModulRoutes.secondBanner.path
                      }
                    >
                      <span>
                        <i className="ni ni-bold-right"></i>
                      </span>
                      {t("second_banner")}
                    </Link>
                  </li>
                )}

                {hasUserAccessFunctionality([permissionSlugs.banner_announcement]) && (
                  <li>
                    <Link
                      className={`dropdown-item 
                        ${checkActiveRoute(pathname, [
                        bannnerModulRoutes.thirdBanner.path,
                      ])}
                      `}
                      to={
                        routes.bannerModule.path +
                        "/" +
                        bannnerModulRoutes.thirdBanner.path
                      }
                    >
                      <span>
                        <i className="ni ni-bold-right"></i>
                      </span>
                      {t("third_banner")}
                    </Link>
                  </li>
                )}

                {hasUserAccessFunctionality([permissionSlugs.banner_secondary]) && (
                  <li>
                    <Link
                      className={`dropdown-item 
                        ${checkActiveRoute(pathname, [
                        bannnerModulRoutes.forthBanner.path,
                      ])}
                      `}
                      to={
                        routes.bannerModule.path +
                        "/" +
                        bannnerModulRoutes.forthBanner.path
                      }
                    >
                      <span>
                        <i className="ni ni-bold-right"></i>
                      </span>
                      {t("forth_banner")}
                    </Link>
                  </li>
                )}
              </ul>
            </li>
          )}



        {hasUserAccessFunctionality([
          permissionSlugs.manual_notification,
          permissionSlugs.list_manual_notification,
        ]) && (
            <li
              className={`nav-item ${checkActiveRoute(
                pathname,
                [routes.Catalog.path],
                true
              )}`}
            >
              <Link
                href="#"
                className="nav-link"
                data-toggle="collapse"
                data-target="#notification-menu-content"
                aria-expanded={
                  checkActiveRoute(pathname, [routes.Notification.path], true) !=
                    ""
                    ? true
                    : false
                }
              >
                <i className="fas fa-bullhorn" style={{ color: "#16499b" }}></i>{" "}
                {t("notification")}
              </Link>
              <ul
                id="notification-menu-content"
              /* className="menu-content collapse out" */ className={`menu-content collapse ${checkActiveRoute(
                  pathname,
                  [routes.Notification.path],
                  true
                )}`}
              >
                {hasUserAccessFunctionality([
                  permissionSlugs.list_manual_notification,
                ]) && (
                    <li>
                      <Link
                        className={`dropdown-item 
                ${checkActiveRoute(pathname, [
                          notificationRoutes.manualNotification.path,
                        ])}
              `}
                        to={
                          routes.Notification.path +
                          "/" +
                          notificationRoutes.manualNotification.path
                        }
                      >
                        <span>
                          <i className="ni ni-bold-right"></i>
                        </span>
                        {t("manual_notification")}
                      </Link>
                    </li>
                  )}
              </ul>
            </li>
          )}
        {hasUserAccessFunctionality([
          permissionSlugs.maintenance_setting,
          permissionSlugs.whatsapp_notification_setting,
          permissionSlugs.auto_notification_setting,
          permissionSlugs.contact_setting,
        ]) && (
            <li
              className={`nav-item ${checkActiveRoute(
                pathname,
                [routes.Setting.path],
                true
              )}`}
            >
              <Link
                href="#"
                className="nav-link"
                data-toggle="collapse"
                data-target="#setting-menu-content"
                aria-expanded={
                  checkActiveRoute(pathname, [routes.Setting.path], true) != ""
                    ? true
                    : false
                }
              >
                <i className="fas fa-cog" style={{ color: "#16499b" }}></i>{" "}
                {t("setting")}
              </Link>
              <ul
                id="setting-menu-content"
              /* className="menu-content collapse out" */ className={`menu-content collapse ${checkActiveRoute(
                  pathname,
                  [routes.Setting.path],
                  true
                )}`}
              >
                {hasUserAccessFunctionality([
                  permissionSlugs.maintenance_setting,
                ]) && (
                    <li>
                      <Link
                        className={`dropdown-item 
                          ${checkActiveRoute(pathname, [
                          settingRoutes.maintenanceSettingList.path,
                        ])}
                        `}
                        to={
                          routes.Setting.path +
                          "/" +
                          settingRoutes.maintenanceSettingList.path
                        }
                      >
                        <span>
                          <i className="ni ni-bold-right"></i>
                        </span>
                        {t("maintenance_setting")}
                      </Link>
                    </li>
                  )}
                {hasUserAccessFunctionality([
                  permissionSlugs.whatsapp_notification_setting,
                ]) && (
                    <li>
                      <Link
                        className={`dropdown-item 
                          ${checkActiveRoute(pathname, [
                          settingRoutes.whatsappNotificationSettingList.path,
                        ])}
                        `}
                        to={
                          routes.Setting.path +
                          "/" +
                          settingRoutes.whatsappNotificationSettingList.path
                        }
                      >
                        <span>
                          <i className="ni ni-bold-right"></i>
                        </span>
                        {t("whatsapp_notification_setting")}
                      </Link>
                    </li>
                  )}
                {hasUserAccessFunctionality([
                  permissionSlugs.auto_notification_setting,
                ]) && (
                    <li>
                      <Link
                        className={`dropdown-item 
                          ${checkActiveRoute(pathname, [
                          settingRoutes.autoNotificationSettingList.path,
                        ])}
                        `}
                        to={
                          routes.Setting.path +
                          "/" +
                          settingRoutes.autoNotificationSettingList.path
                        }
                      >
                        <span>
                          <i className="ni ni-bold-right"></i>
                        </span>
                        {t("auto_notification_setting")}
                      </Link>
                    </li>
                  )}
                {hasUserAccessFunctionality([
                  permissionSlugs.contact_setting,
                ]) && (
                    <li>
                      <Link
                        className={`dropdown-item 
                          ${checkActiveRoute(pathname, [
                          settingRoutes.socialMediaSettingPageList.path,
                        ])}
                        `}
                        to={
                          routes.Setting.path +
                          "/" +
                          settingRoutes.socialMediaSettingPageList.path
                        }
                      >
                        <span>
                          <i className="ni ni-bold-right"></i>
                        </span>
                        {t("contact_setting")}
                      </Link>
                    </li>
                  )}
              </ul>
            </li>
          )}
        {hasUserAccessFunctionality([
          permissionSlugs.lock_user,
          permissionSlugs.fault_type,
        ]) && (
            <li
              className={`nav-item ${checkActiveRoute(
                pathname,
                [routes.otherModule.path],
                true
              )}`}
            >
              <Link
                href="#"
                className="nav-link"
                data-toggle="collapse"
                data-target="#other-menu-content"
                aria-expanded={
                  checkActiveRoute(pathname, [routes.otherModule.path], true) !=
                    ""
                    ? true
                    : false
                }
              >
                <i className="fas fa-dot-circle" style={{ color: "#16499b" }}></i>{" "}
                {t("other")}
              </Link>
              <ul
                id="other-menu-content"
              /* className="menu-content collapse out" */ className={`menu-content collapse ${checkActiveRoute(
                  pathname,
                  [routes.otherModule.path],
                  true
                )}`}
              >
                {hasUserAccessFunctionality([permissionSlugs.lock_user]) && (
                  <li>
                    <Link
                      className={`dropdown-item 
                    ${checkActiveRoute(pathname, [
                        otherModulRoutes.lockUserList.path,
                      ])}
                  `}
                      to={
                        routes.otherModule.path +
                        "/" +
                        otherModulRoutes.lockUserList.path
                      }
                    >
                      <span>
                        <i className="ni ni-bold-right"></i>
                      </span>
                      {t("lock_user")}
                    </Link>
                  </li>
                )}
                {hasUserAccessFunctionality([permissionSlugs.fault_type]) && (
                  <li>
                    <Link
                      className={`dropdown-item 
                    ${checkActiveRoute(pathname, [
                        otherModulRoutes.fultReportList.path,
                      ])}
                  `}
                      to={
                        routes.otherModule.path +
                        "/" +
                        otherModulRoutes.fultReportList.path
                      }
                    >
                      <span>
                        <i className="ni ni-bold-right"></i>
                      </span>
                      {t("fult_report")}
                    </Link>
                  </li>
                )}
                {hasUserAccessFunctionality([permissionSlugs.marquee_type]) && (
                  <li>
                    <Link
                      className={`dropdown-item 
                    ${checkActiveRoute(pathname, [
                        otherModulRoutes.marqueeReportList.path,
                      ])}
                  `}
                      to={
                        routes.otherModule.path +
                        "/" +
                        otherModulRoutes.marqueeReportList.path
                      }
                    >
                      <span>
                        <i className="ni ni-bold-right"></i>
                      </span>
                      {t("marquee")}
                    </Link>
                  </li>
                )}
                {/* what is new report */}
                {hasUserAccessFunctionality([
                  permissionSlugs.whats_new_report,
                ]) && (
                    <li>
                      <Link
                        className={`dropdown-item 
                    ${checkActiveRoute(pathname, [
                          otherModulRoutes.newReportList.path,
                        ])}
                  `}
                        to={
                          routes.otherModule.path +
                          "/" +
                          otherModulRoutes.newReportList.path
                        }
                      >
                        <span>
                          <i className="ni ni-bold-right"></i>
                        </span>
                        {t("whats_new_report")}
                      </Link>
                    </li>
                  )}



                {/* tutorialVideo */}
                {hasUserAccessFunctionality([
                  permissionSlugs.tutorial_report,
                ]) && (
                    <li>
                      <Link
                        className={`dropdown-item 
                    ${checkActiveRoute(pathname, [
                          otherModulRoutes.tutorialVideoList.path,
                        ])}
                  `}
                        to={
                          routes.otherModule.path +
                          "/" +
                          otherModulRoutes.tutorialVideoList.path
                        }
                      >
                        <span>
                          <i className="ni ni-bold-right"></i>
                        </span>
                        {t("tutorial_video")}
                      </Link>
                    </li>
                  )}
                {/* terms and condition */}
                {hasUserAccessFunctionality([permissionSlugs.terms_and_conditions]) && (
                  <li>
                    <Link
                      className={`dropdown-item 
                    ${checkActiveRoute(pathname, [
                        otherModulRoutes.TermsAndCondition.path,
                      ])}
                  `}
                      to={
                        routes.otherModule.path +
                        "/" +
                        otherModulRoutes.TermsAndCondition.path
                      }
                    >
                      <span>
                        <i className="ni ni-bold-right"></i>
                      </span>
                      {t("terms_and_condition")}
                    </Link>
                  </li>
                )}

              </ul>
            </li>
          )}
      </ul>
    </div>
  );
}
